import * as React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Seo from "../components/seo";
import {graphql} from "gatsby";
import Gallery from "@browniebroke/gatsby-image-gallery";
import * as styles from "../styles/o-projektu.module.scss";
import Layout from "../components/layout/layout";


const AboutPage = ({data}) => {
  const about = data?.api?.about;

  const images = about?.aboutGallery?.map(({file, meta}) => ({
    ...file.childImageSharp,
    caption: meta.title.length > 0 ? meta.title : ""
  }));

  const lightboxOptions = {
    imageLoadErrorMessage: 'Nelze načíst obrázek',
    nextLabel: 'Další',
    prevLabel: 'Předchozí',
    zoomInLabel: 'Přiblížit',
    zoomOutLabel: 'Oddálit',
    closeLabel: 'Zavřít',
  }

  return (
    <Layout contactInfo={data?.api?.contactInfo}>
      <Seo title={"O Projektu"} />
      <div className={styles.container}>
        <h1>O Projektu</h1>
        <div className={styles.image}>
          <GatsbyImage image={getImage(about?.aboutPageImage?.file)}
                       alt={"Ilustrace"}
                       objectFit={"cover"}
          />
        </div>
        <div className={styles.text} dangerouslySetInnerHTML={{__html: about?.text}} />
        {images?.length > 0 && (
          <div className={styles.galleryWrapper}>
            <h4>Galerie</h4>
            <Gallery images={images} lightboxOptions={lightboxOptions} imgClass={styles.galleryImage} />
          </div>
        )}
      </div>

    </Layout>
  )
}

export const query = graphql`
    query {
        api {
            contactInfo:contactSingleton {
                email
                phone
                name
            }
            about:aboutSingleton {
                text
                aboutPageImage {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
                aboutGallery {
                    meta
                    path
                    file {
                        childImageSharp {
                            thumb: gatsbyImageData(
                                width: 270
                                height: 270
                                placeholder: BLURRED
                            )
                            full: gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }
`;

export default AboutPage;
